export var SearchList = [
  { labe: '物料编码', code: 'materialCode', type: 'input', placeholder: '编码/名称' },
  {
    labe: '创建时间',
    code: 'cjTime',
    type: 'daterange'
  },
  {
    labe: '附件名称',
    code: 'fileName',
    type: 'input'
  }
]
export var SearchData = {
  materialCode: '',
  cjTime: '',
  fileName: ''
}
export var tableField = [
  { label: '物料编码', code: 'materialCode', type: 'input', width: '70px' },
  { label: '供应商名称', code: 'supplierName', type: 'input', width: '160px' },
  { label: '附件名称', code: 'fileName', type: 'input', width: '220px' },
  { label: '创建时间', code: 'createDate', type: 'input', width: '90px' },
  { label: '下载时间', code: 'modifyDate', type: 'input', width: '90px' },
  {
    label: '操作',
    code: 'act',
    width: '50px',
    type: 'action',
    actionData: [
      {
        actionLabel: '下载',
        operationButton: 'see'
      }
    ]
  }
]

export var tableField1 = [
  { label: '物料编码', code: 'materialCode', type: 'input', width: '70px' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '160px' },
  { label: '附件名称', code: 'fileName', type: 'input', width: '220px' },
  { label: '创建时间', code: 'createDate', type: 'input', width: '90px' },
  { label: '下载时间', code: 'modifyDate', type: 'input', width: '90px' },
  {
    label: '操作',
    code: 'act',
    width: '50px',
    type: 'action',
    actionData: [
      {
        actionLabel: '下载',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = [
]
